.footer{
  &-logo{
    &-ae{
      height:30px;
    }
    &-verfund{
      
    }
  }
}
